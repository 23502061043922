<template>
  <!-- 航研企业往来对账函页面 -->
  <div class="ContactPerson">
    <div class="searchNav">
      <!-- <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button class="button1" type="text" @click="EditEmail"
        >配置邮箱</el-button
      > -->
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.Keyword"
          placeholder="请输入承运商名称/编码"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
        <el-button
          class="button"
          @click="
            () => {
              (queryParam.Keyword = ''), getDataList();
            }
          "
          >重置</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <el-table-column prop="Code" label="对账单号" width="150">
        </el-table-column>
        <el-table-column prop="CusId" label="客户编号" width="150">
        </el-table-column>
        <el-table-column prop="CusName" label="客户名称"> </el-table-column>
        <el-table-column prop="EndDate" label="截止日期" width="150">
          <template slot-scope="scope">
            {{ scope.row.EndDate?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="State" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.State == 0">待确认</el-tag>
            <el-tag v-if="scope.row.State == 1" type="success">已确认</el-tag>
            <el-tag v-if="scope.row.State == 2" type="danger">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            >

            <el-button type="text" @click="handleShare(scope.row)"
              >分享</el-button
            >
            <el-button type="text" @click="handleDelete(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <!-- 企业往来对账单分享 -->
    <el-dialog
      title="微信扫一扫分享"
      :visible.sync="wxVisible"
      width="300px"
      append-to-body
      :before-close="
        () => {
          wxVisible = false;
        }
      "
    >
      <div style="display: flex; justify-content: center">
        <vue-qr :margin="0" :text="wxCode" :size="160"></vue-qr>
      </div>
    </el-dialog>
    <Email ref="Email" />
    <EditForm ref="editForm" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import VueQr from "vue-qr";
import radioGroup from "@/components/CompanyRadioGroup.vue";
import EditForm from "./ReconciliationForm.vue";
import Email from "./Email.vue";
export default {
  props: {},
  components: {
    EditForm,
    radioGroup,
    Email,
    VueQr,
  },
  mounted() {},
  created() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      wxCode: "",
      wxVisible: false,
      sendMethods: "wx",
      sendVisible: false,
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      fileList: [],
      queryParam: {
        Condition: "CusId",
        Keyword: "",
      },
      loading: false,
      dialogVisible: false,
      Email: "",
      row: {},
      dialogLoading: false,
    };
  },
  methods: {
    // 企业往来对账单分享
    handleShare(row) {
      let token = sessionStorage.getItem("token");
      console.log("分享了", row, token);
      this.wxVisible = true;
      this.row = row;

      this.wxCode =
        location.href.split("#")[0] +
        "#/HydzdShare?Id=" +
        this.row.Id +
        "&time=" +
        row.EndDate +
        "&title=" +
        row.CusName +
        "&token=" +
        token;
      console.log("开始企业往来对账单分享了", this.wxCode);
    },

    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        return this.$message.error("请选择公司");
      } else {
        this.$http
          .post(
            "/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" +
              this.queryParam.SupplieId,
            {}
          )
          .then((res) => {
            console.log(res);
            if (res.Success) {
              this.$refs.editForm.openForm(
                "新增对账函",
                res.Data.Content,
                this.queryParam.SupplieId
              );
            } else {
              this.$message.error(res.Msg);
            }
          });
      }
    },
    // 企业往来对账单列表删除
    handleDelete(row) {
      console.log("开始删除了", row);
      this.$confirm("此操作将永久删除当前行数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/HY/hy_bill/DeleteData", [row.Id])
            .then((resJson) => {
              this.loading = false;
              if (resJson.Success) {
                this.$message({
                  type: "success",
                  message: "已删除!",
                });
              } else {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              }
              // 刷新列表
              this.getDataList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // this.$refs.editForm.openForm("编辑对账函", row.Id, row.Company);
    },
    // 查看
    handleView(row) {
      console.log("查看", row);
      let url =
        `${location.href.split("#")[0]}#/HydzdShare?Id=${row.Id}&time=${
          row.EndDate
        }&title=${row.CusName}&isShow=1&Code=` + row.Code;
      window.open(url, "_blank");
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    // 获取企业往来对账单列表
    getDataList() {
      this.loading = true;
      // 待更换接口*****************************************************
      this.$http
        .post("/HY/hy_bill/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
          // PageIndex: this.pagination.current,
          // PageRows: this.pagination.pageSize,
          // SortField: this.sorter.field || "Id",
          // SortType: this.sorter.order,
          // Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.send {
  display: flex;
  justify-content: space-around;
  .sendItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      display: block;
      width: 50px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
.del {
  color: red;
}
.button {
  background-color: #d50700;
  border: #d50700;
}

.button:hover {
  background-color: #d50700;
}

.button1 {
  color: #d50700;
}
</style>
